import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import PortableText from "../components/portableText";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      homepageIntro
      _rawHomepageContent(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const IndexPage = ({ data = {} }) => {
  const homepageContent = data.site?._rawHomepageContent;
  return (
    <>
      <SEO />
      {homepageContent && (
        <div className="prose dark:prose-invert prose-lg pb-6 md:py-12">
          <PortableText blocks={homepageContent} />
        </div>
      )}
    </>
  );
};

export default IndexPage;
